import React from "react";
export const CommentList = ({ comments, onEdit, onDelete }) => {
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  console.log("userData commentList", userData)
  const userId = userData.user_id;
  console.log("userID", userId);

  function formatDateTime(dateString, timeString) {
    const date = new Date(`${dateString} ${timeString}`);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${hours}:${minutes} ${ampm}`;

    return `${day}-${month}-${year} ${strTime}`;
  }
  console.log("comments", comments);

  return (
    <div className="comment_scroll_list">
      {comments.length ? (
        comments?.map((comment, index) => (
          <div className="msg_box" key={index}>
            <img src={comment.user_details.user_image} alt="" className="Img-round" />
            <div className="user_comment">
              <span>{comment.user_details.user_name}
                <small className="comment_datetime">{formatDateTime(comment.date, comment.time)}</small>
              </span>
              <p style={{ whiteSpace: "pre-wrap" }}>{comment.comment}</p>
              <div className="d-flex justify-content-end">
                {userId === comment.user_details.user_id
                  && (
                    <button
                      onClick={() => onEdit(comment)}
                      className="actionBtnscom editBtn">

                    </button>
                  )}
                {/* <button onClick={() => onEdit(comment)} className="actionBtnscom editBtn"></button> */}
                {/* <button onClick={() => onDelete(comment.comment_id)}  className="actionBtnscom deleteBtn"></button> */}
              </div>

            </div>
          </div>
        ))
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="commentnoFound">
          No Comments
        </div>
      )}
    </div>
  );
};
