import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import { API_URL } from "../../lib/network/api";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { getAllComments, getTaskDetails, getTimeLogData, postAComment, postTimeLogData, updateETADate, updateTaskStatus } from "../../lib/network/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import closeIcon from "../../assets/images/close.svg";
import editIcon from "../../assets/images/edit.svg";
import Loader from "../../components/Loader/Loader";
import { CommentList } from "./CommentList";

const TaskDetailsPage = () => {
  const [tasksDetails, setTasksDetails] = useState([]);
  const [ETA, setETA] = useState();
  const [loader, setLoader] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [selectedTaggedMembers, setSelectedTaggedMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [existingMembers, setExistingMembers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const loggedinUser = localStorage.getItem("loggedinUser");
  const userData = JSON.parse(loggedinUser);
  const userId = userData.user_id;
  const [isSaving, setIsSaving] = useState(false);
  const [originalCost, setOriginalCost] = useState('');
  const [originalTime, setOriginalTime] = useState('');
  const [originalMembers, setOriginalMembers] = useState([]);
  const [editedCost, setEditedCost] = useState('');
  const [editedTime, setEditedTime] = useState('');
  const [editedMembers, setEditedMembers] = useState([]);
  const userName = userData.user_name;
  const [isAll, setIsAll] = useState(localStorage.getItem('isAll') === 'true');

  const userReportingTo = userData.user_reporting_to;
  const [activeTab, setActiveTab] = useState('comment');
  const navigate = useNavigate();
  const location = useLocation();
  const [tasks, setTasks] = useState([]);

  const queryParams = new URLSearchParams(location.search);
  const statusOptions = ["To Do", "In Progress", "On Hold", "Done by member (In Review)", "Completed/ Approved"];
  const formik = useFormik({
    initialValues: {
      tagmembers: selectedTaggedMembers,
    },
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const handleTimeSpentChange = (e) => {
    const value = e.target.value;
    console.log("value",value);
    if (value === "") {
      formikTimeLog.setFieldValue("timeSpent", "");
      formikTimeLog.setFieldTouched("timeSpent", true, false);
      return;
    }
    const formattedValue = value.match(/^\d+(\.\d{0,2})?$/);
    console.log("formattedValue",formattedValue);
    if (!isNaN(value) && value >= 0 && formattedValue) {
      formikTimeLog.setFieldValue("timeSpent", formattedValue[0]);
      formikTimeLog.setFieldTouched("timeSpent", true, false);
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    try {
      setLoader(true);
      toast.dismiss();
      const params = {
        task_id: Number(taskId),
        task_current_status: newStatus,
        task_from: tasksDetails.owner_name,
        task_assigned_to: tasksDetails.task_assigned_to,
        task_changed_by: userName,
        task_when_changed: tasksDetails.task_when_changed
      };
      await updateTaskStatus(params);
      setTasksDetails(prevState => ({
        ...prevState,
        task_current_status: newStatus
      }));
      setLoader(false);
      toast.success("Status changed successfully!")
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    setExistingMembers(tasksDetails.allow_members_to_comment || []);
  }, [tasksDetails.allow_members_to_comment]);
  const handleTaggedMembersChange = (selectedMembers) => {
    formik.setFieldValue("tagmembers", selectedMembers);
    setSelectedMembers(selectedMembers)
    setTasksDetails((prevTasksDetails) => ({
      ...prevTasksDetails,
      allow_members_to_comment: selectedMembers.map((member) => ({
        id: member.value,
        name: member.label,
      })),
    }));
  };
  useEffect(() => {
    if (tasksDetails) {
      setOriginalCost(tasksDetails.task_cost);
      setOriginalTime(tasksDetails.task_time);
      setOriginalMembers(tasksDetails?.allow_members_to_comment?.map(member => ({ label: member.name, value: member.id })));
      setEditedCost(tasksDetails.task_cost);
      setEditedTime(tasksDetails.task_time);
      setEditedMembers(tasksDetails?.allow_members_to_comment?.map(member => ({ label: member.name, value: member.id })));
    }
  }, [tasksDetails]);

  const handleCostInputChange = (e) => {
    setEditedCost(e.target.value);
    handleCostChange(e);
  };
  const handleTimeInputChange = (e) => {
    setEditedTime(e.target.value);
    handleTimeChange(e);
  };
  const handleMembersChange = (selectedOptions) => {
    setEditedMembers(selectedOptions);
    handleTaggedMembersChange(selectedOptions);
  };
  let originalMemberIds = originalMembers?.map(member => member.value).sort().join(',');
  let editedMemberIds = editedMembers?.map(member => member.value).sort().join(',');

  const hasChanges = () => {
    return (
      editedCost !== originalCost ||
      editedTime !== originalTime ||
      originalMemberIds !== editedMemberIds
    );
  };
  console.log("value of members", originalMemberIds !== editedMemberIds);
  const handleSaveButtonClick = () => {
    if (!hasChanges())
      return;
    if (!tasksDetails || !tasksDetails.task_assigned_to) {
      return;
    }
    const editedMemberIds = selectedMembers
      .filter((member) => member && member.id)
      .map((member) => member.id);
    const existingMemberIds = tasksDetails.allow_members_to_comment
      .filter((member) => member && member.id)
      .map((member) => member.id);
    const combinedMemberIds = editedMemberIds.length > 0 ? [...existingMemberIds, ...editedMemberIds] : existingMemberIds;
    const payload = {
      id: tasksDetails.task_id,
      task_title: tasksDetails.task_title,
      task_owner: tasksDetails.task_owner,
      task_eta: tasksDetails.task_eta,
      eta_in_date_time: tasksDetails.eta_in_date_time,
      task_priority: tasksDetails.task_priority,
      stream: tasksDetails.stream,
      task_details: tasksDetails.task_details,
      task_assigned_to: tasksDetails.task_assigned_to,
      task_tags: tasksDetails.task_tags,
      task_owner_id: tasksDetails.task_owner_id,
      task_cost: editedCost !== undefined ? editedCost : tasksDetails.task_cost,
      task_time: editedTime !== undefined ? editedTime : tasksDetails.task_time,
      allow_members_to_comment: combinedMemberIds
    };
    setIsSaving(true);
    axios
      .put(`${API_URL}task/task_update`, payload)
      .then(response => {
        setExistingMembers(selectedMembers);
        console.log(response.data);
        toast.success("Details updated successfully");
        setIsSaving(false);
        setOriginalCost(editedCost);
        setOriginalTime(editedTime);
        setOriginalMembers(editedMembers);
      })
      .catch(error => {
        console.error('There was a problem with your Axios request:', error);
        toast.error('There was a problem updating the task details.');
        setIsSaving(false);
      });
  };
  const taskId = queryParams.get("id");
  useEffect(() => {
    setLoader(true);
    if (taskId) {
      fetchTaskDetails(taskId);
      getActivityLog(taskId);
      fetchComments();
      setLoader(true);
    } else { navigate("/my-tasks"); }
  }, []);

  const fetchTaskDetails = (t_id) => {
    const param = {
      task_id: t_id,
    };
    getTaskDetails(param)
      .then((res) => {
        setTasksDetails(res.data.data);
        setETA(res.data.data.estimation_Date);
      })
      .catch((err) => {
        setTasksDetails([]);
        setETA();
      })
      .finally(() => {
      });
  };
  const handleCostChange = (e) => {
    const { value, keyCode } = e.target;
    if (keyCode === 8) {
      setEditedCost("");
      return;
    }
    let newValue = value.replace(/[^0-9.,]/g, "");
    const [integerPart, decimalPart] = newValue.split(".");
    let formattedIntegerPart = integerPart.replace(/,/g, "").slice(0, 8);
    // formattedIntegerPart = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    newValue = decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart.slice(0, 2)}`
      : formattedIntegerPart;
    setEditedCost(newValue);
  };
  const handleTimeChange = (e) => {
    let value = e.target.value.replace(/[^0-9.,]/g, "");
    const [integerPart, decimalPart] = value.split(".");
    let formattedIntegerPart = integerPart.replace(/,/g, "").slice(0, 3);
    // formattedIntegerPart = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    value = decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart.slice(0, 2)}`
      : formattedIntegerPart;
    setEditedTime(value);
  };
  const handleKeyDown1 = e => {
    e.preventDefault();
  };
  const handleEtaChange = (value) => {
    const requestData = {
      eta_in_date_time: value,
      task_owner_id: tasksDetails.task_owner_id,
      id: tasksDetails.task_id,
    };
    setETA(value);
    updateETADate(requestData)
      .then((response) => {
        const date = response.data.data.eta_in_date_time.split("T")[0];
        setETA(date);
        fetchTaskDetails();
      })
      .catch((error) => {
      });
  };
  const getMinDate = () => {
    const today = new Date(); const year = today.getFullYear(); let month = today.getMonth() + 1; let day = today.getDate();
    month = month < 10 ? `0${month}` : month; day = day < 10 ? `0${day}` : day; return `${year}-${month}-${day}`;
  };
  const fetchReadOnlyETA = () => {
    var inputDateString = tasksDetails.estimation_Date;
    var inputDate = new Date(inputDateString);
    var day = inputDate.getDate();
    var month = inputDate.getMonth() + 1;
    var year = inputDate.getFullYear();
    var formattedDateETA = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
    return formattedDateETA;
  }
  const handleEditTask = (e) => {
    e.preventDefault();
    const path = `/task?id=${taskId}`; navigate(path);
  };
  const getPriorityLabel = (priority) => {
    const priorityMap = { H: "High", M: "Medium", L: "Low", };
    return priorityMap[priority] || priority;
  };
  const getActivityLog = async (t_id) => {
    try {
      setLoader(true);
      const params = {
        task_id: t_id,
      };
      const response = await getTimeLogData(params);
      setActivityList(response.data.data || []);
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  const fetchComments = () => {
    const params = {
      task_id: taskId, user_id: userId,
    };
    setLoader(true);
    getAllComments(params)
      .then((res) => {
        if (res.data && res.data.data) {
          setCommentList(res.data.data);
        } else { }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setCommentList([]);
        } else { }
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const handlePostComment = (comment) => {
    setLoader(true);
    const data = {
      user_id: userId,
      task_id: taskId,
      commentText: comment,
      task_title: tasksDetails.task_title,
      task_assign_to: tasksDetails.task_assigned_to,
      task_owner: tasksDetails.owner_name.trim(),
    };
    postAComment(data)
      .then((res) => { fetchComments(); }).catch((error) => { }).finally(() => { setLoader(false); });
  };
  const validationSchemaTimeLog = Yup.object({
    taskDescription: Yup.string()
      .required("Task description is required")
      .min(5, "Task description must contain at least 5 characters")
      .max(200, "Task description exceed 200 characters")
      .matches(/^[^\s][a-zA-Z0-9\s\-.,!"'?/()_$%&:;<>{}]*$/, {
        message: "Only alphanumeric are allowed",
      }),
      timeSpent: Yup.number()
      .required("Time spent is required")
      .min(0.01, "Invalid time format. Please use decimal format")
      .max(8, "Time spent cannot exceed 8 hours")
      .test('is-decimal', 'Invalid time format. Please use decimal format', value => 
        /^\d+(\.\d{1,2})?$/.test(value)
      )
      .test('max-decimal', 'Time spent cannot exceed 8.00 hours', value => 
        value <= 8.00
      ),
  });
  const formikTimeLog = useFormik({
    initialValues: { taskDescription: "", timeSpent: "", },
    validationSchema: validationSchemaTimeLog,
    onSubmit: async (values) => {
      const trimmedTaskDescription = values.taskDescription.trim();
      setIsSaving(true)
      toast.dismiss()
      try {
        const data = {
          task_id: taskId, time_logged: parseFloat(values.timeSpent).toFixed(2), activity: trimmedTaskDescription, user_id: userId,
        };
        await postTimeLogData(data);
        getActivityLog(taskId);
        setIsSaving(false)
        formikTimeLog.resetForm();
        toast.success('Time added successfully!');
      } catch (error) { }
    },
  });
  // useEffect(() => { getMembers(); }, []);
  // const getMembers = async () => {
  //   const response = await axios.get(`${API_URL}task/getAllUserNames`);
  //   setMembers(response?.data?.data?.map(member => ({ value: member.user_id, label: member.user_name })));
  // };
  const validationSchemaComment = Yup.object({
    comment: Yup.string()
      .required("Comment is required")
      .min(5, "Comment must be at least 5 characters")
  });
  const formikComment = useFormik({
    initialValues: {
      comment: "",
    }, validationSchema: validationSchemaComment,
    onSubmit: (values, { resetForm }) => {
      const trimmedComment = values.comment.trim();
      setIsSaving(true);
      toast.dismiss()
      handlePostComment(trimmedComment);
      setIsSaving(false);
      resetForm();
      toast.success('Comment added successfully!')
    },
  });
  const formikETA = useFormik({
    initialValues: {},
    onSubmit: (values) => {
    },
  });
  const renderTooltip = (props) => (
    <Tooltip id="editable-tooltip" {...props}>
      ETA is editable
    </Tooltip>
  );
  const allowedUserIds = tasksDetails.allow_members_to_comment;
  const fetchData = async (isChecked, streamId) => {
    const streamQueryParam = streamId && streamId !== 'all' ? `&stream=${streamId}` : '';
    setLoader(true);
    try {
      const response = await fetch(`${API_URL}task/get2WeeksOldTasks?userId=${userId}&alltask=${isChecked || false}${streamQueryParam}`);
      const data = await response.json();
      setTasks(data.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoader(false);
    }
  };
  
  
  const handleCloseModal = () => {
    const selectedStreamInArchive = localStorage.getItem("selectedStreamInArchive")
    fetchData(isAll, selectedStreamInArchive); 
    navigate("/archive-task");
  };

  return (
    <Modal show={true} onHide={handleCloseModal} className="task-detail-modal">
      <Modal.Header closeButton>
        <div className="heading-left-col">
          <span
            className={`tag_priority ${tasksDetails?.task_priority === "H"
              ? ""
              : tasksDetails?.task_priority === "L"
                ? "bg-grey"
                : "bg-medium"
              }`
            }
            data-bs-toggle="modal"
            href="#exampleModalToggle"
            role="button"
          >
            {tasksDetails
              ? getPriorityLabel(tasksDetails.task_priority)
              : null}
          </span>
          <h4 className="taskdetails-title mb-0 me-3 ">
            {tasksDetails.task_title
              ? tasksDetails.task_title
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
              : ''}
          </h4>
          <h6 className="taskdetails-stream">{tasksDetails.stream_name}</h6>
        </div>
        {tasksDetails.task_owner_id === userData.user_id && (
          <a
            href=""
            className="edit_detail ms-2 "
            onClick={(e) => handleEditTask(e)}
          >
            <img src={editIcon} alt="" />
          </a>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="">
          {!tasksDetails.task_id ? (<span style={{ color: 'grey', fontSize: '1.2em' }}> No Data Found </span>
          ) : (<div className=" create_Tasks">
            <div className="">
              <ul className="task-list-info">
                <li>
                  <label className="">Created By:</label> {tasksDetails.owner_name}
                </li>
                <li>
                  <label className=""> Assigned To:</label> {tasksDetails.task_assign_name}

                </li>
                <li>
    <label className=""> Created date:</label> 
    {tasksDetails.task_created_at ? formatDate(tasksDetails.task_created_at) : "- "}
  </li>
              </ul>
              <div className="row align-items-center">
                <div className="col-md-5">
                  <div className="row">
                    {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                      <div className="col">
                        <form onSubmit={formikETA.handleSubmit}>
                          <label className="form-label">ETA :</label>
                          <div className="form_text">
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <input
                                type="date"
                                onKeyDown={handleKeyDown1}
                                className="form-control"
                                id="task_eta"
                                name="task_eta"
                                placeholder="ETA"
                                onChange={(e) => handleEtaChange(e.target.value)}
                                min={getMinDate()}
                                value={ETA}
                              />
                            </OverlayTrigger>
                          </div>
                        </form>
                      </div>
                    ) : (
                      <div className="col">
                        <label className="form-label me-2">ETA :</label>
                        <div className="form_text">
                          <input
                            type="text"
                            className="form-control eta-input"
                            id="task_eta"
                            name="task_eta"
                            placeholder="ETA"
                            disabled
                            value={fetchReadOnlyETA()}
                          />
                        </div>
                      </div>
                    )}
                    {tasksDetails.task_current_status !== "Completed/ Approved" && (
                      <div className="col">
                        <div className="mb-0">
                          <label className="form-label">Status</label>
                          <select
                            className="form-select"
                            value={tasksDetails.task_current_status}
                            onChange={handleStatusChange}
                            disabled={!(userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to)}
                          >
                            <option value="">Select status</option>
                            {statusOptions?.map((status, index) => (
                              <option key={index} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="cost-time-section">
                    <div className="row">
                      {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                        <div className="col">
                          <label className="form-label me-3"> Cost(£):</label>
                          <div className="form_text">
                            <input
                              type="text"
                              className="form-control"
                              id="task_cost"
                              name="task_cost"
                              placeholder="Cost"
                              value={editedCost}
                              onChange={handleCostInputChange}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="col mb-3">
                          <label className="form-label  me-3">Cost(£):</label>
                          <div className="form_text">
                            <input type="number"
                              className="form-control"
                              id="task_cost"
                              name="task_cost"
                              placeholder="Cost"
                              value={tasksDetails.task_cost}
                              disabled
                            /> </div>
                        </div>
                      )}
                      {userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to ? (
                        <div className="col">
                          <label className="form-label">Time (In hours):</label>
                          <div className="form_text">
                            <input type="text" className="form-control" id="task_time" name="task_time"
                              placeholder="Time" value={editedTime} onChange={handleTimeInputChange} /> </div> </div>
                      ) : (
                        <div className="col">
                          <label className="form-label">Time (In hours):</label>
                          <div className="form_text">
                            <input
                              type="text"
                              className="form-control"
                              id="task_time"
                              name="task_time"
                              placeholder="Time"
                              disabled
                              value={tasksDetails.task_time}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-auto align-self-end">
                        <button
                          className="btn btn-sm btn-primary" type="button" onClick={handleSaveButtonClick}
                          disabled={isSaving || !hasChanges() || !(userData.user_id === tasksDetails.task_owner_id || userData.user_id === tasksDetails.task_assigned_to)} >
                          Save </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label className="form-label mb-0 me-3">Details :</label>
              <div className="textarea-type mb-3">
                {tasksDetails.task_details !== null ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tasksDetails.task_details,
                    }} ></div>
                ) : (
                  "N/A"
                )}
              </div>
            </div>
            <div className="custom-tab">
              <div className="tab-buttons">
                <button
                  className={activeTab === 'comment' ? 'active' : 'inactive'}
                  onClick={() => handleTabClick('comment')} >
                  Comments
                </button>
                <button
                  className={activeTab === 'time' ? 'active' : 'inactive'}
                  onClick={() => handleTabClick('time')}>
                  TimeSheet
                </button>
              </div>
              <div className="tab-content">
                {activeTab === 'comment' && <div>
                  <div className="">
                    <section className="detail_comments"></section>
                    {(tasksDetails.task_owner_id === userData.user_id || tasksDetails.task_assigned_to === userData.user_id ||
                      allowedUserIds.some(item => item.id === userData.user_id)) && (
                        <form onSubmit={formikComment.handleSubmit}>
                          <div className="task-comment_box">
                            <div className="col me-3">
                              <textarea
                                className={`form-control ${formikComment.touched.comment &&
                                  formikComment.errors.comment
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                name="comment"
                                id="comment"
                                cols="30"
                                rows="10"
                                placeholder="Add your comment here"
                                value={formikComment.values.comment}
                                onChange={formikComment.handleChange}
                                onBlur={formikComment.handleBlur}
                              ></textarea>
                              {formikComment.touched.comment &&
                                formikComment.errors.comment && (
                                  <div className="invalid-feedback">
                                    {formikComment.errors.comment}
                                  </div>
                                )}
                            </div>
                            <div className="col-auto">
                            <button className="btn btn-sm btn-primary align-self-end" type="submit"  disabled={isSaving} >
                              Save
                            </button>
                            </div>
                          </div>
                        </form>
                      )}
                    <CommentList comments={commentList} />
                  </div>
                </div>}
                {activeTab === 'time' &&
                  <div className="">
                    <form onSubmit={formikTimeLog.handleSubmit}>
                      <div className="mb-0 h-100">
                        {(tasksDetails.task_owner_id === userData.user_id || tasksDetails.task_assigned_to === userData.user_id) && (
                          <div className="row">
                            <div className="col-lg-12 mb-2">
                              <label className="form-label">What you did for this task?</label>
                              <textarea
                                className={`form-control ${formikTimeLog.touched.taskDescription &&
                                  formikTimeLog.errors.taskDescription
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                name="taskDescription" value={formikTimeLog.values.taskDescription}
                              
                                // onChange={(e) => {
                                //   formikTimeLog.handleChange(e);
                                //   formikTimeLog.setFieldTouched('taskDescription', true, false); // Mark field as touched
                                // }}
                                onChange={(e) => {
                                  if (e.target.value.length <= 200) { // Set the character limit here
                                    formikTimeLog.handleChange(e);
                                    formikTimeLog.setFieldTouched('taskDescription', true, false); // Mark field as touched
                                  }
                                }}
                                 onBlur={formikTimeLog.handleBlur}
                                cols="30" rows="10" placeholder="Enter task here" ></textarea>
                              {formikTimeLog.touched.taskDescription &&
                                formikTimeLog.errors.taskDescription && (
                                  <div className="invalid-feedback">
                                    {formikTimeLog.errors.taskDescription} </div>)} </div>
                            <div className="col-lg-12">
                              <label className="form-label">How much time spent ?</label>
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1"> <input type="text"
                                  className={`form-control w-100 ${formikTimeLog.touched.timeSpent &&
                                    formikTimeLog.errors.timeSpent
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  id="timeSpent" name="timeSpent" value={formikTimeLog.values.timeSpent}
                                  onChange={handleTimeSpentChange} onBlur={formikTimeLog.handleBlur}
                                  placeholder="Use '0.5' for half an hour" />
                                  {formikTimeLog.touched.timeSpent &&
                                    formikTimeLog.errors.timeSpent && (
                                      <div className="invalid-feedback">
                                        {formikTimeLog.errors.timeSpent}
                                      </div>)}</div>
                                <button
                                  className="btn btn-primary btn-sm ms-2" type="submit" disabled={!formikTimeLog.isValid || isSaving} >
                                  Save </button></div></div> </div>)}
                        {!loader ? (
                          <div className="table-responsive mt-3">
                            <table className="table">
                              <thead>
                                <tr> <th>Member Name</th><th>Activity</th> <th>Date</th><th>Time spent</th> </tr>
                              </thead>
                              <tbody>
                                {activityList.length ? (
                                  activityList?.map((activity, index) => (
                                    <tr key={index}> <td>{activity.user_info.user_name}</td> <td>{activity.activity}</td>
                                      <td>
                                        {new Date(activity.createdAt).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric',
                                        })}
                                      </td>
                                      <td>{activity.time_logged}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={4}>
                                      <div className="text-center">
                                        No Record Found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {activityList.length > 0 && (
                                  <tr className="total">
                                    <td></td>
                                    <td></td>
                                    <td>Total</td>
                                    <td>{(activityList[0].totalTime)}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div
                            className="modal-body-loading"
                            style={{ position: "relative", top: "6rem" }}
                          >
                            Loading...
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
          </div>
          )}
          {loader && <Loader />}
          <ToastContainer />
        </div>
      </Modal.Body>
    </Modal>);
};
export default TaskDetailsPage;