import React, { useEffect, useRef, useState } from "react";
import chat from "../../assets/images/chat.svg";
import Timer from "../../assets/images/timer.svg";
import trash from "../../assets/images/trash.svg";
import CustomModal from "../CustomComponents/CustomModal";
import closeWhiteCircle from "../../assets/images/close-icon.svg";
import { getTaskDetails, postAComment } from "../../lib/network/api";
import { ToastContainer , toast } from "react-toastify";
import { useModal } from "../../lib/contexts/ModalContext";
import ActivityTable from "./ActivityTable";
import Loader from "../../components/Loader/Loader";
import DetailModalContent from "./DetailModalContent";
import { deleteTask } from "../../lib/network/api";
import { useNavigate } from "react-router-dom";
import user from "../../assets/images/user-icon.png"
import { position } from "stylis";
import { logDOM } from "@testing-library/react";
import MyTasks from "./MyTasks";
import profile from "../../assets/images/profile_image.png";


import {  useDispatch } from 'react-redux';
import { toggleShowCard , setShowCardFalse } from '../../redux/action/showCardSlice';
import { setCardId } from '../../redux/action/cardIdSlice';
import TaskDetailsPage from "./TaskDetailsPage";

const TaskCard = ({
  task, provided, userId, userName, streamsData, userReportingTo, getMyTaskList, isDragging , members
}) => {
  const { priority, description, count, title, task_id, user_image, task_owner, task_owner_id, task_update_days,
    differenceInDays, task_assign_to, latestDate, stream_name, task_cost, task_time } = task;

  const { isCardModalOpen, openCardModal } = useModal();
  const { isTimeLogModalOpen, openTimeLogModal, closeModal, cardId, setCardId, taskDetails, fetchTaskDetails,
    addComment, activityList, commentList, setCommentList, setActivityList, getActivityLog } = useModal();
  const stream = streamsData?.find(stream => stream.stream_id === task.stream_id);
  const tasksCardRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [showAssignedToMe, setShowAssignedToMe] = useState(false);
  const [openModalTaskId, setOpenModalTaskId] = useState(null);
  const navigate = useNavigate();
  const currentDate = new Date();
  const etaDate = new Date(task.eta_in_date_time);
  let date_time_string = task.eta_in_date_time;
  let date_time_obj = new Date(date_time_string);
  let day = date_time_obj.getUTCDate().toString().padStart(2, '0');
  let month = (date_time_obj.getUTCMonth() + 1).toString().padStart(2, '0');
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  let monthName = monthNames[date_time_obj.getUTCMonth()];
  let date_only = `${day} ${monthName}`;



  // useEffect(() => {
  //   if (tasksCardRef.current) {
  //     if ( ) {
  //       tasksCardRef.current.style.borderBottom = "solid 4px #1360D4";
  //     } else {
  //       tasksCardRef.current.style.backgroundColor = "";
  //     }
  //   }
  // }, [task.task_assigned_to_id, userId]);

  // useEffect(() => {
  //   provided.innerRef(tasksCardRef.current);
  // }, [provided]);

  const handleTimeLog = (e, t_id) => {
    e.preventDefault();
    setCardId(t_id);
    e.stopPropagation();
    setOpenModalTaskId(t_id);
    getActivityLog(t_id)
    openTimeLogModal(true);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleCloseModalTimeLog = (e) => {
    e.preventDefault();
    setOpenModalTaskId(null); // Close the timelog modal
  };

  const handlePostComment = (comment) => {
    setLoader1(true);
    const data = {
      user_id: userId,
      task_id: cardId,
      commentText: comment,
      task_title: title,
      task_assign_to: task.task_assigned_to_id,
      task_owner: task_owner.trim(),
    };
    postAComment(data)
      .then((res) => {
        addComment(cardId);
      })
      .catch((error) => {
      })
      .finally(() => {
        setLoader1(false);
      });
  };

  useEffect(() => {
  }, [commentList])
  const handleComments = () => {
    const newCommentCount = task?.comments?.length;
    setCommentCount(newCommentCount);
    setCommentModalOpen(true);
  };

  const handleCloseCommentModal = () => {
    setCommentModalOpen(false);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    closeModal();
  };
 
  //Todo re-arrange 
  
  const dispatch = useDispatch();

  const handleToggle = async (e, id) => {
    console.log("clicked");
    console.log("t_id", id);
    e.preventDefault();
  
    console.log("card made false");
    
    try {
      const res = await fetchTaskDetailsById(id);
      console.log("res line no 135", res);

      if (res===false) {
        dispatch(setShowCardFalse());
        toast.error("Task not found",{
          duration: 3000,
        });
        setTimeout(() => {
          window?.location.reload();
        }, 3000);
      }
      else {
        console.log("else");
        localStorage.setItem("task_id", id);
        dispatch(toggleShowCard());
      } 
    } catch (err) {
      console.log("catch");
      toast.error("Task not found",{
        duration: 3000,
      });
      setTimeout(() => {
        window?.location.reload();
      }, 3000);
      
    }
  };
  
  const fetchTaskDetailsById = async (t_id) => {
    // const randomId = Math.random() < 0.5 ? t_id : 10000000;   //todo: for testing , remove later
    const param = {
      task_id: t_id,
    };
  
    try {
      const res = await getTaskDetails(param);
      if (res.data.code === 200) {
        return true;
      } 
    } catch (err) {
      console.log("catch block line no 166",err)
      return false;
    }
  };
  

  const handleCardClick = (e, t_id) => {
    e.preventDefault();
   
    // navigate(`/task-details?id=${t_id}`);
  };
  // const handleCardClick = (e, t_id) => {
  //   window.open(`/task-details?id=${t_id}`, "_blank");
  // };

  const [reload, setReload] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleDelete = async (e, taskId) => {
    e.preventDefault();
    setConfirmationModalOpen(true);
  }

  const confirmDelete = async (e, taskId) => {
    // console.log("confirmDelete"); 
    setLoader1(true);
    try {
      let res = await deleteTask(taskId, setReload);
      getMyTaskList();
      e.stopPropagation();
    } catch (error) {
      console.error("Error deleting task", error);
    } finally {
      setLoader1(false);
    }
    setConfirmationModalOpen(false);
  };
  const cancelDelete = () => {
    setConfirmationModalOpen(false);
  };

  const truncateHtmlString = (htmlString, maxLength) => {
    if (htmlString != null) {
      const doc = new DOMParser().parseFromString(htmlString, "text/html");
      const truncatedText = doc.body.textContent || "";
      return truncatedText.length > maxLength
        ? `${truncatedText.slice(0, maxLength)}...`
        : truncatedText;
    } else {
      return ""
    }
  };

  const truncatedDescription = truncateHtmlString(description, 100);
  const storedValue = localStorage.getItem('loggedinUser');
  let userData = JSON.parse(storedValue)

  const BandList = JSON.parse(localStorage.getItem("BandList")) || [];
  // console.log("bands list with id :task card", BandList);
  const firstBandStatus = BandList.length > 0 ? BandList[0].setBoardStatus : null;


  return (
    <>
    <ToastContainer/>
      <div className="position-relative">
        <div {...provided.draggableProps} {...provided.dragHandleProps}>
          
          <div
          className={`${task.task_assigned_to_id === userId ? "tasks_card bottom" : "tasks_card" }`}
            onClick={(e)=> handleToggle(e,task_id)}
            // onClick={(e) => handleCardClick(e, task_id)}
            ref={(el) => {
              provided.innerRef(el);
              tasksCardRef.current = el;
            }}>
              <div className="card-header d-flex align-items-center justify-content-between w-100">
                <span
                  className={`tag_priority ${priority === "High"
                    ? ""
                    : priority === "Low"
                      ? "bg-grey"
                      : "bg-medium"
                    }`}
                >
                  {priority}
                </span>
              </div>
              <h3 className="task-title-name">{title}</h3>
              <div className={`duedate ${currentDate >= etaDate ? "overdue" : ""}`}>
                <h6>Due on {date_only ? date_only : "N/A"}</h6>
              </div>

              <div className="lastupdated">
                {differenceInDays === 0 && (
                  <h6 className="" style={{ color: '#949494' }}>Last updated Today</h6>
                )}
                {differenceInDays === 1 && (
                  <h6 className="" style={{ color: '#949494' }}>Last updated Yesterday</h6>
                )}
                {differenceInDays > 1 && (
                  <h6 className="" style={{ color: differenceInDays >= 5 ? ' ' : '#949494' }}>Last updated {differenceInDays} days ago</h6>
                )}
              </div>

                  <div className="task-footer">
                    <div className="taskassigned-user">
                      <a href="" onClick={(e) => e.preventDefault()}>
                      <img  src={task.user_image || profile} alt={`user_image`} />
                      </a>
                      <span>
                        {members.some(member => member.member_id === Number(task?.task_assigned_to_id)) ? task.task_assign_to : "Unassigned"}
                      </span>            
                      </div>
                    <div className="task-shoecase">
                  
                    {((task_owner_id === userData?.user_id) || (userData?.user_id ===task.task_assigned_to_id)) && (
                        <a href="#" className="me-4 timeIcons" onClick={(e) => handleTimeLog(e, task_id)}>
                          <img className="timetask_icon" src={Timer} alt="" />
                        </a>
                      )}
                     
                      {task_owner_id === userData?.user_id || task.task_assign_to && (
                        <a href="" className="taskCount " onClick={(e) => { e.preventDefault(); handleComments(); }} style={{ cursor: "default" }}>
                          <img src={chat} alt="" /> <span>{count}</span>
                        </a>
                      )}

                      {task_owner_id === userData?.user_id && (
                        <a href="#" className="taskCount" onClick={(e) => { e.preventDefault(); handleComments(); }}
                          style={{ cursor: "default" }}
                        >
                          <img src={chat} alt="" /> <span>{count}</span>
                        </a>
                      )}
                 
                    </div>
                  </div>
        
            
              <CustomModal
                isOpen={isCardModalOpen}
                onClose={handleCloseModal}
                title="Card Modal"
                onClick={handleModalClick}
                footerContent={<button onClick={handleCloseModal}>Close</button>}
              >
                <DetailModalContent
                  closeModal={handleCloseModal}
                  taskId={cardId}
                  userName={userName}
                  userReportingTo={userReportingTo}
                  task={task}
                  commentList={commentList}
                  handlePostComment={handlePostComment}
                  loader1={loader1}
                  handleTimeLog={handleTimeLog}
                  taskDetails={taskDetails}
                  fetchTaskDetails={fetchTaskDetails}
                />
              </CustomModal>
            
            {loader1 && <Loader />}
          </div>
        </div>

      { openModalTaskId === task_id &&
      ( <CustomModal
              isOpen={openModalTaskId === task_id}
              onClose={(e) => handleCloseModalTimeLog(e)}
              title="Time Log Modal"
             
            >
              <div
                class="modal-content custom-modal timesheetModal"
                onClick={handleModalClick}
              >
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
                    Time Log
                  </h1>
                  <a href="" onClick={(e) => handleCloseModalTimeLog(e)}>
                    <img
                      src={closeWhiteCircle}
                      alt=""
                      data-bs-dismiss="modal"
                      className=""
                    />
                  </a>
                </div>

                <div class="modal-body">
                  <ActivityTable
                    task_id={cardId}
                    userId={userId}
                    activityList={activityList}
                    setActivityList={setActivityList}
                  />
                </div>
              </div>

            </CustomModal>)
            }

      </div>
      
      {isConfirmationModalOpen && (
        <div className="confirmation-modal modal_class" style={{ width: "50%", overflow: "auto", zIndex: 1000 }}>
          <div className="modal-content">
            <div class="modal-header"><h5 class="modal-title">Delete Task</h5></div>
            <div className="modal-body">
              <p className="h-light-text text-center mt-2">Are you sure you want to delete this card?</p>
              <div className="button-container text-center">
                <button onClick={cancelDelete} className="btn btn-secondary me-2">Cancel</button>
                <button onClick={(e) => { confirmDelete(e, task.task_id) }} className="btn btn-primary">Yes, Delete</button>
              </div>
            </div>

          </div>
        </div>
      )}
    </>
  );
};
export default TaskCard;

